.ContractName {
  width: 120px;
  white-space: pre-wrap;
}

.WorkScope {
  width: 120px;
  white-space: pre-wrap;
}

.SupplierName {
  width: 120px;
  white-space: pre-wrap;
}
